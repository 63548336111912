<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="row" v-else>
      <div class="col-lg-4 col-xlg-3 col-md-5">
        <div class="card">
          <div class="card-body">
            <center class="m-t-30">
              <img
                :src="user.avatar"
                style="width:150px;height:150px"
                alt="user"
                class="img-circle"
              >
              <h4 class="card-title m-t-10">{{user.name}}</h4>
              <!-- <h6 class="card-subtitle">{{user.email}}</h6> -->
              <!-- <div class="row text-center justify-content-md-center">
                <div class="col-4">
                  <a href="javascript:void(0)" class="link">
                    <i class="icon-people"></i>
                    <font class="font-medium">254</font>
                  </a>
                </div>
                <div class="col-4">
                  <a href="javascript:void(0)" class="link">
                    <i class="icon-picture"></i>
                    <font class="font-medium">54</font>
                  </a>
                </div>
              </div>-->
            </center>
          </div>
          <div>
            <hr>
          </div>
          <div class="card-body">
            <small class="text-muted">Email address</small>
            <h6>{{user.email }}</h6>
            <small class="text-muted p-t-30 db">Phone</small>
            <h6>{{user.phone}}</h6>
            <small class="text-muted p-t-30 db">Address</small>
            <h6>{{user.address}}</h6>
            <small class="text-muted p-t-30 db">Social Profile</small>
            <br>
            <button class="btn btn-circle btn-secondary">
              <i class="fa fa-facebook"></i>
            </button>
            <button class="btn btn-circle btn-secondary">
              <i class="fa fa-twitter"></i>
            </button>
            <button class="btn btn-circle btn-secondary">
              <i class="fa fa-youtube"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-xlg-9 col-md-7">
        <div class="card">
          <!-- Nav tabs -->
          <ul class="nav nav-tabs profile-tab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active show"
                data-toggle="tab"
                href="#profile"
                role="tab"
                aria-selected="false"
              >Settings</a>
            </li>
          </ul>
          <!-- Tab panes -->
          <div class="tab-content">
            <!--second tab-->
            <div class="tab-pane active show" id="settings" role="tabpanel">
              <Form/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "../../../components/table/table";
import Form from "./form";
import { RotateSquare5 } from "vue-loading-spinner";
import ImageItem from "../../../components/img/imgItem";

export default {
  components: {
    RotateSquare5,
    Table,
    Form,
    ImageItem
  },
  data() {
    return {
      headers: {
        name: {
          label: "Judul",
          sortable: true
        },
        namaSingkat: {
          label: "Type",
          sortable: false
        },
        phone: {
          key: "age",
          label: "Jumlah Unit profile",
          sortable: true
        }
      }
    };
  },
  computed: {
    state() {
      return this.$store.state.profile;
    },
    base() {
      return this.baseUrl;
    },
    user() {
      return this.$store.state.profile.user;
    }
  },
  mounted() {},
  methods: {
    doDetail(val) {
      this.$store.dispatch("profile/onDetail", val);
    },
    doEdit(val) {
      this.$store.dispatch("profile/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("profile/onDelete", val);
    }
  }
};
</script>
