<template>
  <div class="card-body">
    <form
      class="form-horizontal form-material"
      @submit.prevent="submit"
      enctype="multipart/form-data"
    >
      <div class="form-group">
        <label class="col-md-12">Full Name</label>
        <div class="col-md-12">
          <Input v-model="payload.name" name="name" required type="text" />
        </div>
      </div>
      <div class="form-group">
        <label for="example-email" class="col-md-12">Email</label>
        <div class="col-md-12">
          <Input type="text" name="email" required v-model="payload.email" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div
            :class="['form-group', { 'has-danger': errors.has('password') }]"
          >
            <label class="col-md-12"> Password </label>
            <div class="col-md-12">
              <input
                v-model="payload.password"
                type="password"
                name="password"
                ref="password"
                :class="[
                  'form-control',
                  { 'form-control-danger': errors.has('password') },
                ]"
              />
              <small
                v-if="errors.has('password')"
                class="form-control-feedback"
                >{{ errors.first("password") }}</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-md-12">Phone No</label>
        <div class="col-md-12">
          <Input type="text" v-model="payload.phone" name="phone" />
        </div>
      </div>
      <div class="form-group">
        <label class="col-md-12">NIP</label>
        <div class="col-md-12">
          <Input type="text" v-model="payload.nip" name="nip" />
        </div>
      </div>
      <div class="form-group">
        <label class="col-md-12">NIK</label>
        <div class="col-md-12">
          <Input type="text" v-model="payload.nik" name="nik" />
        </div>
      </div>
      <div class="form-group">
        <label class="col-md-12">Address</label>
        <div class="col-md-12">
          <textarea
            v-model="payload.address"
            rows="5"
            class="form-control form-control-line"
          ></textarea>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-12">Photo Profile</label>
        <div class="col-sm-12">
          <Upload v-model="payload.photos" isImage name="photo" />
        </div>
      </div>
      <!-- <hr />
      <div class="form-group">
        <label class="col-md-12">Tanda Tangan Digital</label>
      </div>
      <div class="form-group">
        <label class="col-md-12">Passphrase</label>
        <div class="col-md-12">
          <Input
            type="password"
            v-model="payload.passphrase"
            name="passphrase"
          />
        </div>
      </div> -->

      <!-- <div class="form-group">
        <label class="col-md-12"
          >Kunci P12
          <span v-if="user.p12_filename"
            >: File P12 sudah ada, upload untuk merubah kunci P12</span
          ></label
        >
        <drop-file @data="assignFilep12"></drop-file>
      </div> -->

      <!-- <div class="form-group">
        <label class="col-md-12">Tanda Tangan <span v-if="user.signature">: File TTD sudah ada, upload untuk merubah tanda tangan</span></label>
        <drop-file-png @data="assignFilePng"></drop-file-png>
      </div> -->
      <!-- <div class="form-group">
        <label class="col-md-12"
          ><small
            >Catatan : Jika anda mengatur passphrase atau Upload P12, anda wajib
            melalukan logout - login kembali, agar sistem dapat mengolah
            passphrase anda pada tanda tangan digital</small
          >
        </label>
      </div> -->

      <div class="form-group">
        <div class="col-sm-12">
          <button class="btn btn-success" type="submit">Update Profile</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Input from "@/components/input/input.vue";
import Upload from "@/components/input/upload.vue";
import axios from "axios";
export default {
  components: {
    Input,
    Upload,
  },
  computed: {
    user() {
      return this.$store.state.profile.user;
    },
  },
  data() {
    return {
      payload: {
        name: "",
        email: "",
        password: "",
        phone: "",
        address: "",
        photos: "",
        nik: "",
        nip: "",
        p12: "",
        // signature: "",
        // passphrase: "",
      },
    };
  },
  mounted() {
    this.payload = Object.assign(this.payload, this.user);
  },
  methods: {
    assignFilep12(p12) {
      this.payload.p12 = p12;
    },
    assignFilePng(signature) {
      this.payload.signature = signature;
    },
    submit() {
      let photo = this.payload.photos.split(",");
      const payload = {
        name: this.payload.name,
        email: this.payload.email,
        password: this.payload.password,
        phone: this.payload.phone,
        address: this.payload.address,
        nik: this.payload.nik,
        nip: this.payload.nip,
        passphrase: this.payload.passphrase,
        photo: photo.length > 1 ? photo[1] : "",
      };
      let newData = new FormData();
      Object.keys(payload).map((el) => {
        newData.append(el, payload[el]);
      });
      // newData.append('p12',this.payload.p12);
      // newData.append('signature',this.payload.signature);
      this.$validator.validateAll().then((success) => {
        if (success) {
          axios
            .post("profile/update-user", newData)
            .then(() => {
              this.$store.dispatch("profile/getProfile");
              const message = {
                title: this.$t("success"),
                text: "Berhasil memperbarui profil",
                type: "success",
              };
              // this.$store.commit("addNotification", message);
              // this.$store.commit("auth/LOGOUT");
              // this.$router.push("/");
            })
            .catch(() => {
              // console.log(err.response)
            });
        }
      });
    },
  },
};
</script>

